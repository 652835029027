<template>
<aheader />
<section class="section">
  <div class="error-message notification is-danger is-light" v-show="errorMsg != ''" v-html="errorMsg"></div>
  <div class="columns">
    <div class="column is-8 is-offset-2">
      <div class="box">
        <div class="title is-size-4">
          <span>お客様登録（檀家様専用）</span>
        </div>
        <div class="columns">
          <div class="column is-offset-2">
            <div class="has-text-left is-size-5">
              お名前
            </div>
          </div>
        </div>
        <div class="field">
          <div class="columns">
            <div class="column is-4 is-offset-2">
              <div class="control">
                <input class="input" type="name" placeholder="姓" v-model="familyName">
              </div>
            </div>
            <div class="column is-4">
              <div class="control">
                <input class="input" type="name" placeholder="名" v-model="firstName">
              </div>
            </div>
          </div>
        </div>
        <div class="field">
          <div class="columns">
            <div class="column is-4 is-offset-2">
              <div class="control">
                <input class="input" type="name" placeholder="セイ" v-model="kanaFamily">
              </div>
            </div>
            <div class="column is-4">
              <div class="control">
                <input class="input" type="name" placeholder="メイ" v-model="kanaFirst">
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-offset-2">
            <div class="has-text-left is-size-5">
              生年月日
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-8 is-offset-2">
            <div class="select">
              <select v-model="birthYear">
                <option disabled value="">年</option>
                <option v-for="year, key in years" :key='key'>{{year}}年</option>
              </select>
            </div>
            <div class="select">
              <select v-model="birthMonth">
                <option disabled value="">月</option>
                <option v-for="month, key in months" :key='key'>{{month}}月</option>
              </select>
            </div>
            <div class="select">
              <select v-model="birthDay">
                <option disabled value="">日</option>
                <option v-for="day, key in days" :key='key'>{{day}}日</option>
              </select>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-offset-2">
            <div class="has-text-left is-size-5">
              性別
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-8 is-offset-2">
            <div class="control">
              <label class="radio">
                <input type="radio" name="answer" value="男性" v-model="picked">
                男性
              </label>
              <label class="radio">
                <input type="radio" name="answer" value="女性" v-model="picked">
                女性
              </label>
              <label class="radio">
                <input type="radio" name="answer" value="指定しない" v-model="picked">
                指定しない
              </label>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-offset-2">
            <div class="has-text-left is-size-5">
              住所
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-8 is-offset-2">
            <div class="has-text-left">
              郵便番号
            </div>
            <div class="control">
              <input class="input" type="email" placeholder="半角数字" v-model="postCode">
            </div>
            <div class="has-text-left mt-3">
              ご住所
            </div>
            <div class="columns">
              <div class="column is-narrow">
                <div class="select">
                  <select v-model="pref">
                    <option disabled value="">都道府県</option>
                    <option v-for="pref, index in prefs" :key='index'>{{pref}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="control">
              <input class="input" type="email" placeholder="以降の住所をご記入ください。" v-model="address">
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-offset-2">
            <div class="has-text-left is-size-5">
              所属支院を選択してください
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-3 is-offset-3">
            <div class="select">
              <select v-model="region">
                <option value='' disabled selected style='display:none;'>所属地域</option>
                <option v-for="place, index in belongsList" :key="index">{{place.region}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-7 is-offset-2">
            <div class="select">
              <select v-model="place">
                <option class="disabled" value='' selected style='display:none;' :disabled="Temples == []">所属支院を選択してください</option>
                <option v-for="temple, index in Temples" :key="index">{{temple}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-offset-2">
            <div class="has-text-left is-size-5">
              電話番号
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-8 is-offset-2">
            <div class="control has-icons-left">
              <input class="input" type="phone" placeholder="電話番号" v-model="newTel">
              <span class="icon is-small is-left">
                <i class="fas fa-phone"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-offset-2">
            <div class="has-text-left is-size-5">
              メールアドレス
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-8 is-offset-2">
            <div class="control has-icons-left">
              <input class="input" type="email" placeholder="メールアドレス" v-model="newMail">
              <span class="icon is-small is-left">
                <i class="fas fa-envelope"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-offset-2">
            <div class="has-text-left is-size-5">
              パスワード
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-8 is-offset-2">
            <div class="control has-icons-left">
              <input class="input" type="password" placeholder="パスワード" v-model="newPass">
              <span class="icon is-small is-left">
                <i class="fas fa-lock"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-offset-2">
            <div class="has-text-left is-size-5">
              パスワード確認用
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-8 is-offset-2">
            <div class="control has-icons-left">
              <input class="input" type="password" placeholder="パスワード確認用" v-model="passAssertion">
              <span class="icon is-small is-left">
                <i class="fas fa-lock"></i>
              </span>
            </div>
          </div>
        </div>
        <button class="button has-background-info-dark has-text-white-ter" @click="enroll">
          <span>
            登録
          </span>
          <span class="icon is-small">
            <i class="fa-solid fa-right-to-bracket"></i>
          </span>
        </button>
      </div>
    </div>
  </div>
</section>
<afooter />
</template>

<script>
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, collection, setDoc, addDoc, serverTimestamp } from 'firebase/firestore';

export default {
  data() {
    return {
      errorMsg: "",
      familyName: "",
      firstName: "",
      kanaFamily: "",
      kanaFirst: "",
      birthYear: "",
      birthMonth: "",
      birthDay: "",
      picked: "",
      postCode: "",
      pref:"",
      address: "",
      newTel: "",
      newMail: "",
      newPass: "",
      passAssertion: "",
      modalClass: "",
      region: "",
      place: "",
      years: [],
      months: ["-", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      days: ["-", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
      belongsList: [
        {region:"北海道", templesList:[
          '札幌念法寺', '旭川念法寺', '室蘭念法寺', '釧路念法寺', '帯広念法寺', '北見念法寺',
          '苫小牧念法寺', '稚内念法寺', '余市念法寺', '遠別念法寺', '厚真念法寺', '函館念法寺'
        ]},
        {region:"東北・関東", templesList:[
          '八戸念法寺', '鹿沼念法寺', '朝霞念法寺', '東京渋谷念法寺', '千葉念法寺'
        ]},
        {region:"東海・北陸", templesList:[
          '新潟念法寺', '富山念法寺', '羽咋念法寺', '金沢念法寺', '加賀念法寺', '福井念法寺', '敦賀念法寺', '長野念法寺',
          '飯山念法寺', '大垣念法寺', '高山念法寺', '可児念法寺', '名古屋念法寺', '伊勢念法寺', '名張念法寺', '志摩念法寺',
          '阿曽浦念法寺', '海山念法寺'
        ]},
        {region:"近畿", templesList:[
          '京都念法寺', '福知山念法寺', '亀岡布教所', '舞鶴念法寺', '岩滝布教所', '守山念法寺', '堺念法寺', '貝塚念法寺',
          '大阪淀川念法寺', '住道念法寺', '水口布教所', '本山教区', '生駒別院', '神戸念法寺', '明石念法寺', '姫路念法寺',
          '洲本念法寺', '播磨野間谷念法寺', '豊岡念法寺', '香住念法寺', '明延念法寺', '朝来念法寺', '篠山念法寺', '十津川念法寺',
          '和歌山念法寺', '御坊念法寺', '田辺念法寺', '有田布教所', '日置布教所', '龍神布教所', '新宮念法寺', '高野口念法寺',
          '本宮念法寺'
        ]},
        {region:"中国・四国", templesList:[
          '鳥取念法寺', '米子念法寺', '生山布教所', '倉吉念法寺', '大田念法寺', '岡山念法寺','倉敷念法寺', '津山念法寺',
           '新見念法寺', '広島念法寺', '萩念法寺', '鳴門念法寺', '阿南念法寺', '高松念法寺', '松山念法寺', '宿毛念法寺'
        ]},
        {region:"九州・沖縄", templesList:[
          '小倉念法寺', '春日念法寺', '長崎念法寺', '熊本念法寺', '大分念法寺', '宇佐念法寺',
           '延岡念法寺', '鹿児島念法寺', '霧島布教所', '奄美念法寺', '沖縄念法寺'
      ]}],
      temples:["先に地域を選択してください"],
      prefs:["北海道",  "青森県",  "岩手県",  "宮城県",  "秋田県",  "山形県",  "福島県",  "茨城県",
              "栃木県",  "群馬県",  "埼玉県",  "千葉県",  "東京都",  "神奈川県",  "新潟県",  "富山県",
              "石川県",  "福井県",  "山梨県",  "長野県",  "岐阜県",  "静岡県",  "愛知県",  "三重県",
              "滋賀県",  "京都府",  "大阪府",  "兵庫県",  "奈良県",  "和歌山県",  "鳥取県",  "島根県",
              "岡山県",  "広島県",  "山口県",  "徳島県",  "香川県",  "愛媛県",  "高知県",  "福岡県",
              "佐賀県",  "長崎県",  "熊本県",  "大分県",  "宮崎県",  "鹿児島県", "沖縄県"]
    }
  },
  created() {
    this.setTimes()
  },
  mounted() {
    this.scrollTop();
  },
  computed: {
    Temples() {
      this.templeSelect()
      return this.temples
    }
  },
  methods: {
    on() {
      if (this.modalClass == "is-active") {
        this.modalClass = ""
      } else {
        this.modalClass = "is-active"
      }
    },
    setTimes() {
      const now = new Date();
      let nowYear = now.getFullYear()
      for (let i=0; i<121; i++) {
        this.years.push((nowYear - 18) - i)
      }
    },
    templeSelect() {
      this.temples = []
      this.belongsList.forEach((item) => {
        if (item["region"] == this.region) {
          this.temples = item["templesList"]
        }
      });
    },
    enroll() {
      this.errorMsg = "";
      if (this.familyName == "") this.addErrMsg("姓（漢字）を入力してください。");
      if (this.firstName == "") this.addErrMsg("名（漢字）を入力してください。");
      if (this.kanaFamily == "") this.addErrMsg("姓（カタカナ）を入力してください。");
      if (this.kanaFirst == "") this.addErrMsg("名（カタカナ）を入力してください。");
      if (this.birthYear == "") this.addErrMsg("生年月日（年）を選んでください。");
      if (this.birthMonth == "") this.addErrMsg("生年月日（月）を選んでください。");
      if (this.birthDay == "") this.addErrMsg("生年月日（日）を選んでください。");
      if (this.picked == "") this.addErrMsg("性別を選んでください。");
      if (this.postCode == "") this.addErrMsg("郵便番号を入力してください。");
      if (this.pref == "") this.addErrMsg("都道府県を選んでください。");
      if (this.address == "") this.addErrMsg("住所を入力してください。");
      if (this.place == "") this.addErrMsg("所属支院を選択してください。");
      if (this.newTel == "") this.addErrMsg("電話番号を入力してください。");
      if (this.newMail == "") this.addErrMsg("メールアドレスを入力してください。");
      if (this.newPass == "") {
        this.addErrMsg("パスワードを入力してください。");
      } else {
        const regex = /^(?=.*?[a-z])(?=.*?\d)[a-z\d]{8,100}$/i;
        if (!regex.test(this.newPass)) {
          this.addErrMsg("パスワードは8文字以上で英字と数字をそれぞれ少なくとも1つは含めてください。");
        } else {
          if (this.newPass != this.passAssertion) {
            this.addErrMsg("入力されたパスワードと確認用パスワードが一致しません。");
            this.passAssertion = "";
          }
        }
      }
      if (this.errorMsg == "") {
        this.errorMsg = "";
        this.sign_up()
        .then(()=>{
          this.$router.push('/home');
        })
        .catch((err)=>{
          console.log(err);
          this.addErrMsg("登録時にエラーが発生しました。");
        })
      } else {
        this.scrollTop();
      }
    },
    async sign_up() {
      const userCredential = await createUserWithEmailAndPassword(getAuth(this.$firebase), this.newMail, this.newPass);
      const user = userCredential.user;
      const data = {
        '姓（漢字）': this.familyName,
        '名（漢字）': this.firstName,
        '姓（カタカナ）': this.kanaFamily,
        '名（カタカナ）': this.kanaFirst,
        '生年月日（年）': this.birthYear,
        '生年月日（月）': this.birthMonth,
        '生年月日（日）': this.birthDay,
        '性別': this.picked,
        '郵便番号': this.postCode,
        '住所（県）':this.pref,
        '住所': this.address,
        '電話番号': this.newTel,
        'email': this.newMail,
        '所属支院地域': this.region,
        '所属支院': this.place,
        '檀家フラグ': true,
        '更新日時': serverTimestamp()
      };
      await setDoc(doc(this.$firestore_db, 'users', user.uid), data);
      await addDoc(collection(this.$firestore_db, `users/${user.uid}/history`), data);
    },
    addErrMsg(msg) {
      if (this.errorMsg != "") this.errorMsg += "<br/>";
      this.errorMsg = this.errorMsg + msg;
    },
    scrollTop() {
      this.$nextTick(() => {
        window.scrollTo(0, 0);
      })
    },
    pushPage(page) {
      this.$router.push(page)
    }
  }
}
</script>
<style scoped>

</style>
